import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import axios from "axios";
import { navigate, Link } from "gatsby";
import {
  BreadCrumb,
  Section,
  SectionPageTitle,
  SectionTitle,
  ExploreMore,
} from "../components/section";
import MapPointerIcon from "../components/Icons/MapPointerIcon";
import PhoneLineIcon from "../components/Icons/PhoneLineIcon";
import PaperPlanIcon from "../components/Icons/PaperPlanIcon";
import ArrowIcon from "../components/Icons/ArrowIcon";
import FormButton from "../components/button/FormButton";
import BreakpointUp from "../components/Media/BreakpointUp";

const ContactGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 -15px;
  justify-content: center;
  ${BreakpointUp.lg`				
        margin:0 -30px;
    `}
`;
const ContactGridItem = styled.li`
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  padding: 0 15px;
  text-align: center;
  ${BreakpointUp.md`		
        flex: 0 0 auto;
        width: auto;
        max-width: none;
        padding:0 30px;
        text-align:left;
    `}
  ${BreakpointUp.lg`				
        display:flex;
        flex-wrap:wrap;
        align-items:center;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `} 
    + li {
    &:before {
      ${BreakpointUp.lg`		
                background-color:#E5E5E5;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -60px;
                width: 1px;
                height: 100%;      
            `}
    }
  }
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  width: 60px;
  height: 60px;
  margin: 0 auto 15px auto;
  ${BreakpointUp.md`		
        margin:0 0 15px;
    `}
  ${BreakpointUp.lg`		
        padding:0 10px;
        margin-bottom:0;
    `}
    & svg {
    fill: #6c7780;
  }
`;
const TextWrap = styled.div`
  display: inline-block;
  ${BreakpointUp.lg`		
        padding:0 15px;
    `}
  & span {
    display: block;
  }
  & p {
    font-weight: 700;
    color: #002337;
    margin-bottom: 0;
    ${BreakpointUp.lg`		
            font-size:18px;
        `}
  }
  & a {
    font-weight: 700;
    color: #002337;
    text-decoration: underline;
    ${BreakpointUp.lg`	
            font-size:18px;
        `}
    &:hover {
      text-decoration: none;
      color: #002337;
    }
  }
`;
const FormColumn = styled.div`
  width: 100%;
  padding: 0 15px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  ${BreakpointUp.lg`				
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
  & p {
    margin-bottom: 40px;
  }
  & form {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
  }
`;
const FormGrid = styled.div`
  display: grid;
  align-items: center;
  grid-column-gap: 30px;
  grid-template-columns: repeat(1, 1fr);
  ${BreakpointUp.lg`                  
    grid-template-columns: repeat(2, 1fr);
  `}
`;


class ContactUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        currentTab: 0,
        first_name: "",
        email: "",
        mobile_no: "",
        zipcode: "",
        state: "",
        comment: "",
    };
    this.changeTab = this.changeTab.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);        
  }
  handleChange(event) {
  const { id, value } = event.target;
  this.setState({
      [id]: value,
  });
  }
  changeTab(data) {
  this.setState({
      currentTab: data,
  });
  }
  handleSubmit(event) {
  event.preventDefault();
  const data = {
      first_name: this.state.first_name,
      email: this.state.email,
      mobile_no: this.state.phone_no,
      state: this.state.state,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
  };
  axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.FORM_CRM_TOKEN },
  })
      .then((res) => {
      this.setState({
          first_name: "",
          email: "",
          mobile_no: "",
          state: "",
          zipcode: "",
          comment: "",
      });
        navigate("/thank-you-get-in-touch/")            
      })
      .catch((error) => {
      alert(error);
      });
  }
  render() {
    return (
      <Layout>
        <SEO title="Contact Mr.Carports for Custom Metal Structures of Your Choice" description="Are you looking for the best metal carports, metal garages, metal barns, or custom metal structures? Contact us today and get your quote!" />
        <Section pt="140px" pb="90px" xpt="110px" xpb="60px" bg="#fff">
          <div className="container">
            <BreadCrumb>
              <Link to="/">Home</Link>/<span>Contact Us</span>
            </BreadCrumb>
            <SectionPageTitle mt="30px" mb="40px" textAlign="center">
              Contact Information
            </SectionPageTitle>
            <ContactGrid>
              <ContactGridItem>
                <Icon>
                  <MapPointerIcon />
                </Icon>
                <TextWrap>
                  <span>Address</span>
                  <p>P.O. Box 57 Dobson NC 27017</p>
                </TextWrap>
              </ContactGridItem>
              <ContactGridItem>
                <Icon>
                  <PhoneLineIcon />
                </Icon>
                <TextWrap>
                  <span>Phone Number</span>
                  <p>
                    <a href="tel:8773505464">(877) 350-5464</a>                    
                  </p>
                </TextWrap>
              </ContactGridItem>
              <ContactGridItem>
                <Icon>
                  <PaperPlanIcon />
                </Icon>
                <TextWrap>
                  <span>Email ID</span>
                  <a href="mailto:sales@mrcarports.com">
                    sales@mrcarports.com
                  </a>
                </TextWrap>
              </ContactGridItem>
            </ContactGrid>
          </div>
        </Section>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F4F8FC">
          <div className="container">
            <FormColumn>
              <SectionTitle mb="20px">Get in touch</SectionTitle>             
              <form onSubmit={this.handleSubmit}>
                <div className="form-group-bordered">
                <input
                    type="text"
                    id="first_name"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                    placeholder="Name"
                />
                </div>
                <FormGrid>
                    <div className="form-group-bordered">
                        <input
                        id="email"
                        type="email"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        placeholder="Email Address"
                        />
                    </div>
                    <div className="form-group-bordered">
                        <input
                        type="tel"
                        id="mobile_no"
                        className="form-control"
                        pattern="[0-9]{10}"
                        onChange={this.handleChange}
                        required
                        placeholder="Phone Number"
                        />
                    </div>
                </FormGrid>
                <FormGrid>
                <div className="form-group-bordered">
                    <select
                    id="state"
                    className="form-control"
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    >
                    <option value="" selected="selected">
                        Select state
                    </option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                    </select>
                </div>
                <div className="form-group-bordered">
                    <input
                    id="zipcode"
                    className="form-control"
                    onChange={this.handleChange}
                    required
                    placeholder="Zip Code"
                    />
                </div>                
                </FormGrid>
                <div className="form-group-bordered">
                    <textarea
                        id="comment"
                        className="form-control"
                        onChange={this.handleChange}
                        required
                        placeholder="Ask Your Query"
                    ></textarea>
                </div>
                <div className="form-action text-center">
                  <FormButton text="Submit" />
                </div>
            </form>
            </FormColumn>
          </div>
        </Section>
      </Layout>
    );
  }
}

export default ContactUsPage;
